import React from "react";
//import { ProductBrowser } from "@ecwid/gatsby-plugin-ecwid";
import Page from "../../components/Page";
import { AspectRatio, Container, Flex, Heading } from "../../components/core";
import { Parallax } from "../../components/animate";
import { getImage } from "gatsby-plugin-image";
import { alpha } from "@theme-ui/color";
import { motion } from "framer-motion";
import SEO from "../../components/SEO";
import { graphql } from "gatsby";
import { useThemeUI } from "theme-ui";

export default function Boutique({ data }) {
  const { theme } = useThemeUI();
  return (
    <Page className="boutique-CMC">
      <SEO
        title="Boutique"
        description="Retrouvez des produits éthiques et responsables dans la boutique Clean my Calanques ou des goodies à nos couleurs pour nous soutenir !"
      />
      <AspectRatio ratio={[1440 / 630, 1440 / 480, 1440 / 315]}>
        <Parallax
          alt="Image en-tête contact"
          image={getImage(data.parallax)}
          backgroundColor={alpha("primary", 0.25)(theme)}
        >
          <Flex
            sx={{
              width: "100%",
              height: "100%",
              py: 0,
              px: [2, null, 0],
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.6, delay: 0.8 }}
            >
              <Heading as="h1" variant="h1" color="white">
                Boutique
              </Heading>
            </motion.div>
          </Flex>
        </Parallax>
      </AspectRatio>
      <Container
        as="section"
        sx={{
          paddingTop: [8, null, 10],
          paddingBottom: 8,
          minHeight: "400px",
        }}
        variant="containerLg"
      >
        {/*   <ProductBrowser storeId="65545267" />*/}
      </Container>
    </Page>
  );
}

export const query = graphql`
  query BoutiqueQuery {
    parallax: file(relativePath: { eq: "boutique-header.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`;
